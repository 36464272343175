:root {
    --bs-info-rgb: 20, 167, 173 !important;
    --bs-primary-rgb: 40, 94, 159 !important;
}

/* cursor pointer */
.myCursor {
    cursor: pointer;
}

/* text decoration none */
.noDecoration {
    text-decoration: none;
}

/* background colors */
.myBgAcqua {
    background-color: #14a7ad !important;
}

.myBgFucsia {
    background-color: #e82283 !important;
}

.myBgDarkgray {
    background-color: #454444 !important;
}

.myBgDark {
    background-color: #111 !important;
}

.myBgBlue {
    background-color: #17365d !important;
}

/* text color */
.myTextLightgray {
    color: rgb(220, 220, 220) !important;
}

.myTextDark {
    color: #444;
}

.myTextAcqua {
    color: #14a7ad !important;
}

.myTextFucsia {
    color: #e82283 !important;
}

.myTextBlue {
    color: #285e9f !important;
}

.myBgWhite {
    background-color: #fff;
}

/* bg transparent */
.myBgTransparent20 {
    background-color: #ffffff2a;
}

.myBgTransparent30 {
    background-color: #00000076;
}

.myBgTransparent50 {
    background-color: #101010d5;
}

.myBgTransparent60 {
    background-color: #101010de;
}

.myBgTransparent90 {
    background-color: #111111f7;
}

/* borders */
.myBorderWhite {
    border: 4px solid #ffffff;
}

/* logo size */
.myLogo100 {
    width: 100px;
    height: 100px;
}

/* myScrollY */
.myScrollY {
    overflow-y: scroll;
}


/* max width */
.myWidth20 {
    width: 20px;
    height: 100%;
}

.myMaxWidth50 {
    max-width: 50px;
}

.myMaxWidth60 {
    max-width: 60px;
}

.myMaxWidth80 {
    max-width: 80px;
}

.myMaxWidth90 {
    max-width: 130px;
}

.myMaxWidth150 {
    max-width: 150px;
}

.myMaxWidth200 {
    max-width: 200px;
}

.myMaxWidth300 {
    max-width: 300px;
}

.myMaxWidth400 {
    max-width: 400px;
}

.myMaxWidth500 {
    max-width: 500px;
}

.myMaxWidth700 {
    max-width: 700px;
}


.myMaxWidth1000 {
    max-width: 1000px;
}

.myMaxWidth1200 {
    max-width: 1200px;
}

.myMaxWidth1400 {
    max-width: 1400px;
}

.myMaxWidth1600 {
    max-width: 1600px;
}

/* text truncate */
.myTruncate2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* video ratio youtube */
.ratio {
    position: relative;
    width: 100%;
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%;
}

/* infinite carousel */
.infinite_carousel {
    overflow-x: hidden;
    overflow-y: visible;
    padding-left: 12px;
}

/* background image */
.myBgImgCover {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* magazines */
.myMagazineHeightMax {
    max-height: 300px;
}

.myMagazineHeightMin {
    max-height: 150px;
}

.magArrowCarousel {
    bottom: -170px;
}

.pressArrowCarousel {
    bottom: -205px;
}

/* spacer media query */

@media only screen and (min-width: 500px) {
    .spacerLg {
        height: 50px;
    }

    .myMaxWidth80 {
        max-width: 120px;
    }

    .myMaxWidth90 {
        max-width: 250px;
    }

    .myMagazineHeightMax {
        max-height: 700px;
    }

    .myMagazineHeightMin {
        max-height: 400px;
    }

    .magArrowCarousel {
        bottom: -370px;
    }

    .pressArrowCarousel {
        bottom: -410px;
    }
}



/* font size */
.myFontSize42 {
    font-size: 42px !important;
}

.myFontSize18 {
    font-size: 18px !important;
}

.myFontSize22 {
    font-size: 22px !important;
}

.myFontSize28 {
    font-size: 28px !important;
}

.myFontSize38 {
    font-size: 38px !important;
}

.myFontSize98 {
    font-size: 98px !important;
}

/* indicatore tondo numeri riviste */
.myNumberCircle {
    height: 160px;
    width: 160px;
    border-radius: 80px;
}

/* filter */
.bwFilter{
filter: grayscale(1) !important;
}